$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $(document).on('click', '.toggle-password > img', function() {
        let input = $($(this).parent().attr("toggle"));
        input.attr("type") === "password" ?
            input.attr("type", "text") :
            input.attr("type", "password");
    })

    $(document).on('click', '.buttons_switch > input', function() {
        if (!this.checked) {
            $('#login-form').fadeOut(1);
            $('#forgot-password-form').fadeOut(1);
            $('#register-form').fadeIn(1);
        } else {
            $('#login-form').fadeIn(1);
            $('#forgot-password-form').fadeOut(1);
            $('#register-form').fadeOut(1);
        }
    })

    function submitAuthForm(form, url, formData, elementIds)
    {
        $.ajax({
            method: 'POST',
            url: url,
            data: formData,
            processData: false,
            contentType: false,
            success: function () {
                window.location.href = '/dashboard';
            },
            error: function (data) {
                let errors = data.responseJSON.errors;
                $.each(elementIds, function(index, value) {
                    if (value in errors) {
                        form.find($("#" + value + "_error")).text(errors[value][0]);
                    } else {
                        form.find($("#" + value + "_error")).text("");
                    }
                });
            }
        });
    }

    $(document).on('submit', '#login-form', function(e) {
        e.preventDefault();
        let form = $(this);
        let url = form.attr('action');
        let formData = new FormData(form[0]);
        let elementIds = ['email', 'password'];
        submitAuthForm(form, url, formData, elementIds);
    });

    $(document).on('submit', '#register-form', function(e) {
        e.preventDefault();
        let form = $(this);
        let url = form.attr('action');
        let formData = new FormData(form[0]);
        let elementIds = ['register_email', 'register_password', 'terms_and_conditions', 'g-recaptcha-response'];
        submitAuthForm(form, url, formData, elementIds);
    })
})
